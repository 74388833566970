.progress_indicator {
  margin-left: 2px;
  background-color: #fa646a;
}

.progress_indicator::before {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  background-color: #fa646a;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: -3px;
}

.progress_indicator::after {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  background-color: #fa646a;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: -3px;
}

.canvas_container canvas{
  color: lightblue
}