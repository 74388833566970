@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }
  h5, p {
    @apply text-sm;
  }
  ul,
  ol {
    display: block;
    list-style: disc outside none;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }
  ol {
    list-style-type: decimal;
  }

  li {
    display: list-item;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    margin-left: 15px;
  }
}

.noto-sans {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
}
