body {
  font-family: "Noto Sans JP Regular", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Noto Sans JP Medium";
  src: local("NotoSansJP-Medium"),
    url("../../assests/fonts/NotoSansJP-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans JP Light";
  src: local("NotoSansJP-Light"),
    url("../../assests/fonts/NotoSansJP-Light.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans JP Regular";
  src: local("NotoSansJP-Regular"),
    url("../../assests/fonts/NotoSansJP-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("../../assests/fonts/Inter-Regular.ttf");
}

.with_roboto {
  font-family: "Roboto", serif;
}

html {
  scroll-behavior: smooth;
}

.circle_progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.down_polygon {
  clip-path: polygon(0 0, 50% 70%, 100% 0);
}

.up_polygon {
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.select_polygon_down_bg {
  background-image: url("../images/icons/polygon_down.webp");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(50% + 1px);
  background-size: 13px;
}

.ql-tooltip {
  left: 0 !important;
}

input,
textarea {
  border-radius: 5px;
  border: 1px solid #ebe4d8;
  background: white;
}

input[type="text"],
input[type="time"] {
  border-radius: 5px;
  border: 1px solid #ebe4d8;
  background: white;
  padding-block: 5px;
  padding-left: 13px;
  font-size: 12px;
}

select {
  border-radius: 5px;
  border: 1px solid #ebe4d8;
  padding: 0;
}

input,
textarea {
  width: 100%;
}

.lds-dual-ring {
  display: inline-block;
  width: 34px;
  height: 24px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 24px;
  margin-left: 4px;
  height: 24px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  border-radius: 5px;
  border: 1px solid #ebe4d8;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e1e3e2;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #789ac2;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #537aa7;
}

.confirm_popup-cancel_button {
  color: #7a7a7a;
  border: 1px solid #7a7a7a;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.confirm_popup-confirm_button {
  color: #fa646a;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  text-transform: capitalize;
}

/* checkbox customizes */
input[type="checkbox"] {
  color: transparent;
  border: 1px solid #ebe4d8;
}

input[type="checkbox"]:hover {
  background-color: white;
  border: 1px solid #ebe4d8;
}

input[type="checkbox"]:checked {
  background-image: url("../images/icons/check.svg");
  background-color: white;
  border: 1px solid #ebe4d8;
}

.input_check_red:checked {
  background-image: url("../images/icons/check_red_bolder.svg") !important;
  background-color: white;
  border: 1px solid #ebe4d8;
}

input[type="range"]:active {
  cursor: grabbing;
}

input[type="range"]:disabled {
  cursor: not-allowed;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 12px;
  color: #bebebe;
  font-weight: 400;
}

.ql-editor {
  min-height: 40px;
}

button:hover {
  background-image: linear-gradient(rgb(0 0 0/15%) 0 0);
}

.form_authoring input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #c5c5c5 !important;
}

.form_authoring input[type="checkbox"] {
  color: transparent;
  border: 1px solid #ebe4d8;
}

.form_authoring input[type="checkbox"]:hover {
  background-color: white;
  border: 1px solid #ebe4d8;
}

.form_authoring input[type="checkbox"]:checked {
  background-image: url("../images/icons/check_red.svg");
  background-color: white;
  border: 1px solid #ebe4d8;
}

.form_authoring input[type="radio"] {
  border: 1px solid #c5c5c5;
  width: 20px;
  height: 20px;
}

.form_authoring input[type="radio"]:checked {
  background: #fa646a;
  box-shadow: inset 0 0 0 4px white;
  -webkit-transition: background 0.15s, box-shadow 0.1s;
  transition: background 0.15s, box-shadow 0.1s;
}

.form_authoring .ql-container.ql-snow {
  border-radius: 7px;
  border: 1px solid #c5c5c5;
}

.form_authoring .no_border_editor .ql-container.ql-snow {
  border: none;
}

.form_authoring .selecting_border_editor .ql-container.ql-snow {
  border-radius: 5px;
  border: 1px solid #75a1d3;
}

.form_authoring .ql-editor {
  padding-block: 10px;
  min-height: 33px;
}

.form_authoring .ql-editor .question_letter {
  padding-block: 10px;
  min-height: 300px;
}

.form_authoring .ql-snow .ql-editor img,
.form_authoring img {
  max-width: 250px;
  width: 100%;
  padding: 5px;
}

.form_authoring ul {
  margin: 0;
}

.form_authoring ul.listbox-dot input[type="checkbox"] {
  border-radius: 100%;
  background-color: #c5c5c5;
}

.form_authoring input[type="checkbox"]:hover {
  cursor: pointer;
}

.form_authoring ul.listbox-dot input[type="checkbox"]:checked {
  background-color: #fa646a;
  border: none !important;
}

.form_authoring ul.listbox-radio input[type="checkbox"] {
  border-radius: 100%;
}

.form_authoring ul.listbox-radio input[type="checkbox"]:checked {
  background: #fa646a;
  box-shadow: inset 0 0 0 4px white;
}

/* Chrome, Safari, Edge, Opera */
.form_authoring .choice_limit input::-webkit-outer-spin-button,
.form_authoring .choice_limit input::-webkit-inner-spin-button {
  -webkit-appearance: auto;
}

/* Firefox */
.form_authoring .choice_limit input[type="number"] {
  -moz-appearance: auto;
}

/*Authoring editor*/
.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}
.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
#letter_question h1 {
  font-size: 2em;
}
#letter_question h2 {
  font-size: 1.5em;
}
#letter_question h3 {
  font-size: 1.17em;
}
#letter_question h4 {
  font-size: 1em;
}
.form_authoring .content_editable:focus {
  outline: none;
}

.form_authoring .highlight_content svg {
  vertical-align: text-bottom;
}
.form_authoring .highlight_content svg {
  display: inline-block;
  vertical-align: middle;
}

.form_authoring .highlight_content span {
  display: inline-block;
  vertical-align: middle;
}

.form_authoring .fill_in_blank_selecting_target {
  border: 1px dashed #75a1d3 !important;
  background: #eaf7fc;
}

.form_authoring .button_hover_none:hover {
  background-image: none !important;
}

input[type="range"].thumb_range_hidden::-webkit-slider-thumb {
  display: none;
}
.exam input[type="text"],
select {
  border-radius: 2px;
  padding: 7px 8px;
  height: 38px;
}
.exam input[type="number"],
select {
  padding-left: 3px;
  padding-right: 0px;
  border-radius: 2px;
  height: 38px;
}

.exam input[type="number"]::-webkit-outer-spin-button,
.exam input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: auto;
  margin: -10px 0px;
}

.modal-score {
  font-size: 14px;
}

.set-img img {
  max-width: 150px;
}
