input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  background-color: #7a7a7a;
}

input[type="checkbox"]:focus {
  --tw-ring-offset-width: 0;
}

input[type="checkbox"]:checked {
  background-image: url("../images/icons/check_red_bolder.svg");
  background-color: white;
  border: 1px solid #ebe4d8;
}
